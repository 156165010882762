import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/Container'
import Headline from 'components/Headline'
import { grey } from 'const/colors'
import { P } from 'components/Heading'
import {
  Slide,
  CarouselImg as Carousel,
  TeamName,
  TaglineStyled,
  PageSectionStyled,
} from './styles'
import Swiper from 'react-id-swiper'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import 'react-id-swiper/src/styles/css/swiper.css'

const Grabbable = styled.div`
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const CarouselImg = styled(Carousel)`
  max-width: 100%;
`

const renderTeamMembers = (members) => {
  let memberElements = []
  members.map(({ elements: { name, job_title, image } }) => {
    const srcLarge = `${image.value[0].url}?w=904`
    memberElements = [
      ...memberElements,
      <Slide teamMember key={name.value}>
        <CarouselImg src={srcLarge} />
        <TeamName>{name.value}</TeamName>
        <P margin="5px 0 50px" fontSize="16px" opacity="0.75">
          {job_title.value}
        </P>
      </Slide>,
    ]
  })
  return memberElements
}

class TeamSection extends React.Component {
  render() {
    const {
      elements: { team_title, team_description, team_members },
      currentBreakpoint,
    } = this.props
    return (
      <PageSectionStyled padding bgColor={grey}>
        <Container maxWidth="820px">
          <Headline>{team_title.value}</Headline>
          <TaglineStyled padding="0 0 20px" richText>
            {team_description.value}
          </TaglineStyled>
        </Container>
        <Container>
          {(currentBreakpoint === 'desktop' ||
            currentBreakpoint === 'desktopLarge') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                rowGap: '56px',
              }}
            >
              {team_members.map(({ elements: { name, job_title, image } }) => (
                <Slide teamMember key={name.value}>
                  <CarouselImg src={`${image.value[0].url}?w=904`} />
                  <TeamName>{name.value}</TeamName>
                  <P fontSize="16px" opacity="0.75">
                    {job_title.value}
                  </P>
                </Slide>
              ))}
            </div>
          )}
        </Container>
        <LazyLoad offset={100}>
          <ScrollAnimation animateIn="fadeIn">
            <Grabbable>
              {currentBreakpoint === 'phone' && (
                <Swiper
                  {...{
                    slidesPerView: 1.5,
                    spaceBetween: 18,
                    centeredSlides: true,
                    loop: true,
                  }}
                >
                  {renderTeamMembers(team_members)}
                </Swiper>
              )}

              {currentBreakpoint === 'tablet' && (
                <Swiper
                  {...{
                    slidesPerView: 4,
                    spaceBetween: 24,
                    centeredSlides: true,
                    loop: true,
                  }}
                >
                  {renderTeamMembers(team_members)}
                </Swiper>
              )}
            </Grabbable>
          </ScrollAnimation>
        </LazyLoad>
      </PageSectionStyled>
    )
  }
}

TeamSection.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(TeamSection)
