import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from 'layouts/Default'
import HeaderSection from 'sections/About/header'
import ImageSection from 'sections/About/image'
import ClientsSection from 'sections/About/clients'
import InvestSection from 'sections/About/invest'
import ActionSection from 'sections/About/action'
import TeamSection from 'sections/About/team'
import TechnologySection from 'sections/About/technology'
import PartnersSection from 'sections/About/partners'
import { graphql } from 'gatsby'

const WhoWeAre = ({ data, ...restProps }) => {
  const buildOGMetadata = () => {
    const { elements } = data.kenticoCloudItemAbout

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }

  return (
    <Layout {...restProps} ogMetadata={buildOGMetadata()}>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_ROBOTS_SITE_URL}/team`}
        />
      </Helmet>
      <HeaderSection {...data.kenticoCloudItemAbout} />
      <ImageSection {...data.kenticoCloudItemAbout} />
      <ClientsSection {...data.kenticoCloudItemAbout} />
      <InvestSection {...data.kenticoCloudItemAbout} />
      <ActionSection {...data.kenticoCloudItemAbout} />
      <TeamSection {...data.kenticoCloudItemAbout} />
      <TechnologySection {...data.kenticoCloudItemAbout} />
      <PartnersSection {...data.kenticoCloudItemAbout} />
    </Layout>
  )
}

WhoWeAre.propTypes = {
  data: PropTypes.object,
}

export default WhoWeAre

export const query = graphql`
  {
    kenticoCloudItemAbout {
      elements {
        title {
          value
        }
        tagline {
          value
        }
        main_image {
          value {
            url
          }
        }
        main_image_mobile_carousel {
          value {
            url
          }
        }
        intro_title {
          value
        }
        intro_text {
          value
        }
        breakout_button {
          value
        }
        breakout_url {
          value
        }
        clients_text {
          value
        }
        client_logos {
          value {
            url
          }
        }
        invest_title {
          value
        }
        invest_content {
          value
        }
        team_title {
          value
        }
        team_description {
          value
        }
        team_members {
          elements {
            name {
              value
            }
            job_title {
              value
            }
            image {
              value {
                url
              }
            }
          }
        }
        toolkit_title {
          value
        }
        toolkit_items {
          elements {
            title {
              value
            }
            description {
              value
            }
            images {
              assets {
                url
              }
            }
          }
        }
        partners_title {
          value
        }
        partners_tagline {
          value
        }
        partners {
          elements {
            logo {
              value {
                url
              }
            }
            tagline {
              value
            }
            slug {
              value
            }
          }
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
  }
`
