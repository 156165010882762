import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from 'components/Container'
import Section from 'components/Section'
import PageSection from 'components/Section/pageSection'
import Tagline from 'components/Tagline'
import media from 'styles/media'
import { Flex } from '@rebass/grid'
import { black, white } from 'const/colors'

const Logo = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;

  ${media.tablet`
    width: 28%;
    margin: 30px 0 30px 2%;
  `}

  ${media.phone`
    width: 45%;
  `}

  img {
    max-width: 100%;
  }
`

// TODO Fix widths
const renderLogos = (logos) => {
  let logoElements = []
  logos.forEach(({ url }, i) => {
    logoElements.push(
      <Logo key={i} px={1}>
        <img src={url} />
      </Logo>
    )
  })
  return logoElements
}

const ClientsSection = ({ elements: { clients_text, client_logos } }) => {
  return (
    <PageSection padding bgColor={black} color={white}>
      <Container>
        <Tagline maxWidth="680px">{clients_text.value}</Tagline>
        <Flex flexWrap="wrap" justifyContent="space-between" mx={-1}>
          {renderLogos(client_logos.value)}
        </Flex>
      </Container>
    </PageSection>
  )
}

ClientsSection.propTypes = {
  elements: PropTypes.object,
}

export default ClientsSection
