import React from 'react'
import PropTypes from 'prop-types'
import Container from 'components/Container'
import Section from 'components/Section'
import PageSection from 'components/Section/pageSection'
import Headline from 'components/Headline'
import { Flex } from '@rebass/grid'
import { H4, P } from 'components/Heading'
import { HR, TechnologyItem } from './styles'

const renderTechnologies = (technologies) => {
  let technologyElements = []
  technologies.map(({ elements: { title, description, images } }, i) => {
    technologyElements = [
      ...technologyElements,
      <Section key={i} px={2} width={[1, 1 / 2, 1 / 3]}>
        <TechnologyItem>
          {images.assets.map(({ url }, i) => (
            <img src={url} key={i} />
          ))}
          <HR />
          <H4 fontSize="18px" tFontSize="18px" margin="18px 0 4px" bold>
            {title.value}
          </H4>
          <P opacity="0.25">{description.value}</P>
        </TechnologyItem>
      </Section>,
    ]
  })

  return technologyElements
}

const TechnologySection = ({ elements: { toolkit_title, toolkit_items } }) => (
  <PageSection padding>
    <Container>
      <Headline marginBottom="85px" tMarginBottom="60px">
        {toolkit_title.value}
      </Headline>
      <Flex mx={-1} flexWrap="wrap">
        {renderTechnologies(toolkit_items)}
      </Flex>
    </Container>
  </PageSection>
)

TechnologySection.propTypes = {
  elements: PropTypes.object,
}

export default TechnologySection
