import React from 'react'
import PropTypes from 'prop-types'
import { color } from 'const/colors'
import styled from 'styled-components'
import media from 'styles/media'

const PageSection = styled.section`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: ${color.primary.o100};

  ${media.tablet`
    padding: 40px 0 60px;
  `};
`

const OuterContainer = styled.div`
  max-width: 2560px;
  width: 100%;
  margin: 0 248px;

  ${media.desktop`
    margin: 0 136px;
  `}

  ${media.tablet`
    margin: 0 24px;
  `}
`

const Container = styled.div`
  max-width: 820px;

  ${media.tablet`
    max-width: 560px;
  `};
`

const Headline = styled.h1`
  color: ${color.white.o100};
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 0;

  ${media.tablet`
    max-width: 80%;
    font-size: 24px;
    line-height: 40px;
  `};
`

const Tagline = styled.p`
  color: ${color.white.o75};
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 0;

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
  `};
`

export default function InvestSection({
  elements: { invest_title, invest_content },
}) {
  return (
    <PageSection padding="0" bgColor={color.primary.o100}>
      <OuterContainer>
        <Container>
          <Headline color={color.white.o100}>{invest_title.value}</Headline>
          <Tagline richText tabletMb="0" noMob>
            {invest_content.value}
          </Tagline>
        </Container>
      </OuterContainer>
    </PageSection>
  )
}

InvestSection.propTypes = {
  elements: PropTypes.object,
}
