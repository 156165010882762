import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import Section from 'components/Section'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import 'react-id-swiper/src/styles/css/swiper.css'
import { Autoplay } from 'swiper/dist/js/swiper.esm'
import { Slide, CarouselImg as Carousel } from './styles'

const BannerImg = styled.img`
  width: 100%;
`

const CarouselImg = styled(Carousel)`
  width: 100%;
`

const Grabbable = styled.div`
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const renderCompanyImages = ({ value: images }) =>
  images.map(({ url }) => {
    const srcSmall = `${url}?w=1534`
    return (
      <Section key={url} width={[1]}>
        <Slide key={url}>
          <CarouselImg src={srcSmall} />
        </Slide>
      </Section>
    )
  })

const swiperParams = {
  slidesPerView: 1,
  centeredSlides: true,
  loop: true,
  modules: [Autoplay],
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
}

const ImageSection = ({
  elements: { main_image_mobile_carousel, main_image },
  currentBreakpoint,
}) => {
  const srcMedium = `${main_image.value[0].url}?w=1688`
  const srcLarge = `${main_image.value[0].url}?w=1688`
  return (
    <LazyLoad offset={300}>
      <ScrollAnimation animateIn="fadeIn">
        <Grabbable>
          {currentBreakpoint === 'phone' && (
            <Swiper {...swiperParams}>
              {renderCompanyImages(main_image_mobile_carousel)}
            </Swiper>
          )}
          {(currentBreakpoint === 'tablet' ||
            currentBreakpoint === 'desktop' ||
            currentBreakpoint === 'desktopLarge') && (
            <>
              {main_image && (
                <picture>
                  <source
                    srcSet={srcMedium}
                    media="(min-width: 768px) and (max-width: 1279px) and (min-resolution: 190dpi)"
                  ></source>
                  <source
                    srcSet={srcLarge}
                    media="(min-width: 1280px) and (min-resolution: 190dpi)"
                  ></source>
                  <source
                    srcSet={srcMedium}
                    media="(min-width: 768px) and (max-width: 1279px)"
                  ></source>
                  <source
                    srcSet={srcLarge}
                    media="(min-width: 1280px)"
                  ></source>
                  <source srcSet={main_image.value[0].url}></source>
                  <BannerImg src={main_image.value[0].url} />
                </picture>
              )}
            </>
          )}
        </Grabbable>
      </ScrollAnimation>
    </LazyLoad>
  )
}

ImageSection.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(ImageSection)
