import styled from 'styled-components'
import Section from 'components/Section'
import { color } from 'const/colors'
import media from 'styles/media'
import Tagline from 'components/Tagline'
import PageSection from 'components/Section/pageSection'

export const TaglineStyled = styled(Tagline)`
  margin-bottom: 0px;

  ${media.tablet`
    margin-bottom: 0px;
  `}
`

export const PageSectionStyled = styled(PageSection)`
  padding-bottom: 70px;

  ${media.tablet`
    padding-bottom: 0;
  `}
`

export const Slide = styled(Section)`
  max-height: 340px;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    margin: 10px 0;
  `}
`

/* ${({ teamMember }) => teamMember && `max-width: 360px;`} */

export const CarouselImg = styled.img`
  overflow: hidden;
  margin-bottom: 16px;
`

export const TeamName = styled.h4`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${color.black.o100};
  margin: 0;

  ${media.tablet`
    font-size: 18px;
  `}
`

export const TechnologyItem = styled(Section)`
  margin: 10px 0 100px;
  min-height: 200px;

  & img {
    max-height: 35px;
    margin-right: 10px;
  }

  ${media.tablet`
    margin: 10px 0 60px;
  `};
`

export const HR = styled.hr`
  background-color: ${color.black.o100};
  margin: 20px 0;
  opacity: 0.25;
  height: 0.25px;

  ${media.tablet`
    margin: 18px 0;
  `};
`
