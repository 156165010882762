import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section/headerSection'

const HeaderSection = ({ elements: { title, tagline } }) => (
  <Section header headline={title.value} tagline={tagline.value} richText />
)

HeaderSection.propTypes = {
  elements: PropTypes.object,
}

export default HeaderSection
